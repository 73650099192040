.board {
    border: 2px solid salmon;
    display: flex;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    margin: 0 auto;
    /* Magic number to prevent emoji from growing too much */
    max-width: 579px;
}
