.tile {
    appearance: none;
    background: darkmagenta;
    border: 2px solid salmon;
    flex: 1 0 33%;
    padding: 0;
    position: relative;
}

.tile::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: 100%;
}

.tile:focus {
    background: springgreen;
    outline: none;
}

.highlight {
    background-color: greenyellow;
}

.tile > svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}