html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}

.Board_board__T5-oK {
    border: 2px solid salmon;
    display: flex;
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    margin: 0 auto;
    /* Magic number to prevent emoji from growing too much */
    max-width: 579px;
}

.Tile_tile__3d9Zk {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: darkmagenta;
    border: 2px solid salmon;
    flex: 1 0 33%;
    padding: 0;
    position: relative;
}

.Tile_tile__3d9Zk::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: 100%;
}

.Tile_tile__3d9Zk:focus {
    background: springgreen;
    outline: none;
}

.Tile_highlight__Y44-r {
    background-color: greenyellow;
}

.Tile_tile__3d9Zk > svg {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.Game_title__3HLoG {
    color: blue;
    font-size: 3rem;
    line-height: 1.5;
    margin-top: 0;
    text-align: center;
}

.Game_resetButton__1bht_ {
    background-color: olivedrab;
    border-radius: 25px;
    color: navajowhite;
    display: block;
    font-size: 2rem;
    font-weight: bold;
    margin: 2rem auto;
    padding: 1rem 2rem;
}

.App_app__3FYPx {
  background-color: lightgreen;
  bottom: 0;
  left: 0;
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 0;
}

